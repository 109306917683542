import "../App.css";

const DemoMessage = () => {
  return (
    <div className="mobile-message">
      Editor not work on mobile device. Access to Desktop to manage database
      editor.
    </div>
  );
};

export default DemoMessage;
